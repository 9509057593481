import React from "react";
import PropTypes from "prop-types";

const SizeTable = ({ size }) => (
  <table className="table table-bordered">
    <thead>
      <tr>
        <th>Size</th>
        <th>Price</th>
        <th> Key </th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr key={size.sizetype}>
        <td>{size.sizetype}</td>
        <td>{size.price}</td>
        <td>{size.reg_id}</td>
        <td>
          <button
            className="btn btn-outline-danger"
            // onClick={() => onDeleteClick(size)}
          >
            Delete
          </button>
        </td>
      </tr>
    </tbody>
  </table>
);

SizeTable.propTypes = {
  size: PropTypes.array.isRequired,
  // onDeleteClick: PropTypes.func.isRequired,
};

export default SizeTable;
