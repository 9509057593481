import React from "react";
import PropTypes from "prop-types";

const ListItem = ({ arr, label }) => (
  <div className="card">
    <div className="card-header">{label}</div>
    <ul className="list-group list-group-flush">
      {arr.map((item) => {
        console.log("item = ", item);
        return (
          <li className="list-group-item" key={item}>
            {item}
          </li>
        );
      })}
    </ul>
  </div>
);

ListItem.propTypes = {
  arr: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  // onDeleteClick: PropTypes.func.isRequired,
};

export default ListItem;
