import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadProducts, saveProduct } from "../../redux/actions/productActions";
import PropTypes from "prop-types";
import ProductForm from "./ProductForm";
import { newProduct } from "../../tools/mockData";
import Spinner from "../common/Spinner";
import { toast } from "react-toastify";

function ManageProductsPage({
  products,
  loadProducts,
  saveProduct,
  history,
  ...props
}) {
  const [product, setProduct] = useState({ ...props.product });
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (products.length === 0) {
      loadProducts().catch((error) => {
        alert("Loading products failed " + error);
      });
    } else {
      setProduct({ ...props.product });
    }
  }, [props.product]);

  function handleChange(event) {
    const { name, value } = event.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  }

  function formIsValid() {
    console.log("Forms is valid");
    const { name, description } = product;
    const errors = {};

    if (!name) errors.name = "Name is required.";
    if (!description) errors.description = "Description is required";

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleSave(event) {
    console.log("Reached here ");
    product.data_type = "product";
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    saveProduct(product)
      .then(() => {
        toast.success("Product saved.");
        history.push("/products");
      })
      .catch((error) => {
        setSaving(false);
        setErrors({ onSave: error.message });
      });
  }

  return products.length === 0 ? (
    <Spinner />
  ) : (
    <ProductForm
      product={product}
      errors={errors}
      onChange={handleChange}
      onSave={handleSave}
      saving={saving}
    />
  );
}

ManageProductsPage.propTypes = {
  product: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  loadProducts: PropTypes.func.isRequired,
  saveProduct: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export function getProductById(products, id) {
  return products.find((product) => product.id === id) || null;
}

function mapStateToProps(state, ownProps) {
  const id = ownProps.match.params.id;
  const product =
    id && state.products.length > 0
      ? getProductById(state.products, id)
      : newProduct;
  return {
    product,
    products: state.products,
  };
}

const mapDispatchToProps = {
  loadProducts,
  saveProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageProductsPage);
