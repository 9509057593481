import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => (
  <div className="jumbotron">
    <h1 className="display-3">Ice Ice Baby Admin</h1>
    <p className="lead">Interface to for managing Ice Ice Baby store</p>
    <Link to="about" className="btn btn-primary btn-lg">
      Learn more
    </Link>
  </div>
  //
  // <div className="jumbotron">
  //     <h1 className="display-3">Hello, world!</h1>
  //     <p className="lead">This is a simple hero unit, a simple jumbotron-style component for calling extra attention to
  //         featured content or information.</p>
  //     <hr className="my-4">
  //         <p>It uses utility classes for typography and spacing to space content out within the larger container.</p>
  //         <p className="lead">
  //             <a className="btn btn-primary btn-lg" href="#" role="button">Learn more</a>
  //         </p>
  // </div>
);

export default HomePage;
