const products = [
  {
    id: 1,
    type: "icecream",
    name: "IceCream",
    flavor: ["vanilla", "choclate"],
    options: ["Cup", "Cone"],
    description: "Edy's finest ice cream",
  },
  {
    id: 2,
    type: "smoothie",
    name: "Smoothie",
    flavor: ["vanilla", "choclate"],
    options: ["Strawberry", "Banana"],
    description: "Made with real ingredients",
  },
  {
    id: 3,
    type: "milkshake",
    name: "Milk Shake",
    flavor: ["vanilla", "choclate"],
    options: ["Caramel", "Strawberry"],
    description: "Made with premium ingredients",
  },
];

const newProduct = {
  id: null,
  type: "",
  name: "",
  flavor: null,
  options: null,
  description: "",
};

module.exports = {
  products,
  newProduct,
};
