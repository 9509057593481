import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ProductList = ({ products, onDeleteClick }) => {
  const sortedProducts = products.sort((a, b) => {
    console.log(parseInt(a.id));
    if (parseInt(a.id) < parseInt(b.id)) {
      return -1;
    }
    if (parseInt(a.id) > parseInt(b.id)) {
      return 1;
    }

    return 0;
  });

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Size</th>
          <th>Price</th>
          <th>Key</th>
          <th>Description</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {sortedProducts.map((product) => {
          return (
            <tr key={product.id}>
              <td>
                <Link to={"/product/" + product.id}>{product.name}</Link>
              </td>
              <td>{product.prod_type}</td>
              <td>{product.size[0].sizetype}</td>
              <td>{product.size[0].price}</td>
              <td>{product.size[0].reg_id}</td>
              <td>{product.description}</td>
              <td>
                <button
                  className="btn btn-outline-danger"
                  onClick={() => onDeleteClick(product)}
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default ProductList;
