import React from "react";
import PropTypes from "prop-types";
import TextInput from "../common/TextInput";
import SelectInput from "../common/SelectInput";
import SizeTable from "../common/SizeTable";
import ListItem from "../common/ListItem";
import { Link } from "react-router-dom";

const ProductForm = ({
  product,
  onSave,
  onChange,
  saving = false,
  errors = {},
}) => {
  const prodTypes = [
    "icecream",
    "smoothie",
    "milkshake",
    "snowball",
    "topping",
    "container",
  ];

  // console.log("Product", product);
  return (
    <form onSubmit={onSave}>
      <h2>{product.id ? "Edit" : "Add"} Product</h2>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}
      <TextInput
        name="name"
        label="Name"
        value={product.name}
        onChange={onChange}
        error={errors.name}
      />

      <SelectInput
        name="prod_type"
        label="Product Type"
        value={product.prod_type || ""}
        defaultOption="Select Product Type"
        options={prodTypes.map((type) => ({
          value: type,
          text: type,
        }))}
        onChange={onChange}
        error={errors.prod_type}
      />

      <TextInput
        name="description"
        label="Description"
        value={product.description}
        onChange={onChange}
        error={errors.description}
      />

      <TextInput
        name="image_url"
        label="Image"
        value={product.image_url}
        onChange={onChange}
        error={errors.image_url}
      />

      <TextInput
        name="reg_id"
        label="Register ID"
        value={product.reg_id}
        onChange={onChange}
        error={errors.reg_id}
      />

      <label> Size Options </label>

      {product.size && product.size.length > 0 ? (
        <SizeTable size={product.size[0]} />
      ) : (
        <hr />
      )}

      <table>
        <tbody>
          <tr style={{ padding: "10px" }}>
            <td style={{ padding: "10px" }}>
              {product.store_location && product.store_location.length > 0 ? (
                <ListItem arr={product.store_location} label="Store Location" />
              ) : (
                <hr />
              )}
            </td>
          </tr>
          <tr style={{ padding: "10px" }}>
            <td style={{ padding: "10px" }}>
              {product.flavor && product.flavor.length > 0 ? (
                <ListItem arr={product.flavor} label="Flavor" />
              ) : (
                <hr />
              )}
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              {product.flavor && product.flavor.length > 0 ? (
                <ListItem arr={product.options} label="Options" />
              ) : (
                <hr />
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <button
          style={{ margin: "10px" }}
          type="submit"
          disabled={saving}
          className="btn btn-primary"
        >
          {saving ? "Saving..." : "Save"}
        </button>

        <button className="btn btn-primary">
          <Link style={{ color: "white", margin: "10px" }} to={"/products"}>
            Cancel
          </Link>
        </button>
      </div>
    </form>
  );
};

ProductForm.propTypes = {
  product: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  saving: PropTypes.bool,
};

export default ProductForm;
