import { handleResponse, handleError } from "./apiUtils";
const baseUrl = "https://api.iceicebabystore.com" + "/products/";

export function getProducts() {
  return fetch(baseUrl)
    .then((res) =>
      res.json().then((result) => {
        return result.Items;
      })
    )
    .catch(handleError);
}

export function saveProduct(product) {
  debugger;
  return fetch(baseUrl, {
    mode: "cors",
    method: "POST", // POST for create, PUT to update when id already exists.
    headers: { "content-type": "application/json" },
    body: JSON.stringify(product),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteProduct(productId) {
  return fetch(baseUrl + productId, { method: "DELETE" })
    .then(handleResponse)
    .catch(handleError);
}
